import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/ItHarware.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Enhanced Security",
    paragraph: "Increase security with secure products that protect your data.",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Ongoing Support  ",
    paragraph: "Receive continued support from leading suppliers.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Pre-Sales Consultation",
    paragraph:
      "Let an experienced IT professional guide you towards the right products.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Premium Products ",
    paragraph:
      "High-quality products that improve the efficiency of your business. ",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Stress-Free Setup",
    paragraph: "Sit back while the provider sets up the hardware for you.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Streamline Business Tasks",
    paragraph: "Simplify the running of your business to enhance productivity.",
  },
];

export const HeaderData = {
  src: Internet,
  to: Product_Quote_Routes.ITHardware,
  title: "IT Hardware",
  subtitle: "Boost Your Business with IT Hardware",
  paragraph: "Reap the rewards of premium equipment today",
};

export const DescriptionContent = {
  title: "Why Do You Need IT Hardware?",
  paragraph:
    "IT hardware can help to drive growth within a business. Connect with suppliers of high-quality and essential IT hardware products, including monitors, hard disk drives, and power supply units.",
  to: Product_Quote_Routes.ITHardware,
  profitPoint1: "Competitive pricing ",
  profitPoint2: "Increased business efficiency ",
  profitPoint3: "Improved customer service",
  profitPoint4: "Reduced costs",
};

export const FAQS = [
  {
    title: "What is IT hardware?",
    answer:
      " IT hardware, as opposed to software, refers to the physical elements of a computer system, such as a keyboard.  ",
  },
  {
    title: "How will my business benefit from IT hardware?",
    answer:
      "Efficient IT hardware can streamline many of your business operations, enhance your security, and help you to stay organised.   ",
  },
  {
    title: "What IT hardware do I need?",
    answer:
      "That depends on many factors, including the size of your business. Generally, all businesses need a laptop or desktop computer, a network server solution, a wireless router, and a printer.  ",
  },
  {
    title: "How much does IT hardware cost?",
    answer:
      "The price of IT hardware can vary according to what products you procure and what suppliers you use. ",
  },
  {
    title: "Can I set up IT hardware myself?",
    answer:
      " You could set up your hardware yourself, but it can expensive and stressful if you don’t know what you’re doing. By letting a professional take care of it, you can focus on running your business instead. ",
  },
  {
    title: "How does IT hardware save me money?",
    answer:
      "IT hardware automates certain tasks and increase business productivity. In turn, this reduces costs.  ",
  },
  {
    title: "How can Digital Comparison get me IT solutions?",
    answer:
      "We match you up with the top IT hardware suppliers in the country. Once you receive your offers, you can choose the best one for you. ",
  },
  {
    title: "Which IT solutions provider is the best?",
    answer:
      "All our supply partners are trusted and qualified industry leaders. Your individual business needs will determine the right one for you. ",
  },
];
